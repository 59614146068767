<template>
  <div id="chart">
    <apexchart
      type=line
      height=350
      :options="chartOptions"
      :series="series" />
  </div>
</template>

<script>
export default {
  name: 'AssetsOverTime',
  props: {
    data: {
      type: Array,
      required: true,
    }
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'assets-over-time',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          }
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: value => value.toFixed(0)
          }
        },
        xaxis: {
          categories: Object.keys(this.data),
          type: 'datetime',
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 4,
        },
        colors: [process.env.VUE_APP_GRAPH_COLOR,],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          },
        },
      }
    },

    series() {
      return [{
        name: 'Assets',
        data: Object.values(this.data)
      }]
    }
  }
}
</script>
